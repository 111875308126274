import { reactive, inject, computed, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import Notify from 'quasar/src/plugins/Notify.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import moment from "moment";
export default function useUser() {
  const router = useRouter();
  const route = useRoute();
  const store = useStore();
  const $http = inject("$http");
  const $q = useQuasar();

  let curUser = computed(() => store.state.user);

  let data = reactive({
    sales_growth: [],
    visit: [],
    customer_growth: [],
  });

  let getGrowth = async () => {
    let resp = await $http.get(`/manager/sales-growth`);
    data.sales_growth = resp.data;
  };

  let getVisitMap = async () => {
    let resp = await $http.get(`/manager/visit-map`);
    data.visit = resp.data;
  };

  let getCostumerGrowth = async () => {
    let resp = await $http.get(`/manager/customer-growth`);
    data.customer_growth = resp.data;
  };

  return {
    store,
    router,
    route,
    data,
    curUser,
    getGrowth,
    getVisitMap,
    getCostumerGrowth,
    $q,
  };
}
