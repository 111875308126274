<template>
  <div class="l-wrapper">
    <div class="q-pa-md column q-gutter-sm">
      <q-select
        outlined
        label="Sales"
        emit-value
        map-options
        :options="sales.data.sales"
        v-model="options.selSales"
        @update:model-value="filteringVisit"
      ></q-select>
      <q-select
        outlined
        label="Visit type"
        :options="visit.data.visitType"
        v-model="options.selType"
        @update:model-value="filteringVisit"
      ></q-select>
      <q-input label="Tanggal" v-model="dateModel" outlined readonly>
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy
              ref="qDateProxy"
              transition-show="scale"
              transition-hide="scale"
            >
              <q-date
                v-model="options.date"
                range
                mask="YYYY-MM-DD"
                @update:model-value="filteringVisit"
              >
                <div class="row items-center justify-between">
                  <q-btn
                    outline
                    color="primary"
                    label="Reset"
                    @click="
                      options.date = {
                        from: null,
                        to: null,
                      };
                      filteringVisit();
                    "
                  ></q-btn>
                  <q-btn v-close-popup label="Close" color="primary" flat />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
    </div>

    <q-scroll-area class="l-grow bg-grey-2">
      <q-list separator>
        <q-item
          class="bg-grey-4"
          clickable
          v-for="(val, i) in filteredVisit"
          :key="i"
          @click="goDetail(val.city)"
          v-ripple
        >
          <q-item-section> City {{ val.city }} </q-item-section>
          <q-item-section> {{ val.total }} </q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>
    <!-- <q-btn
      unelevated
      class="no-border-radius"
      label="DOWNLOAD EXCELL"
      color="primary"
      @click="downloadExcell"
    ></q-btn> -->
  </div>
</template>
<script>
import moment from "moment";
import { onMounted, ref, computed, reactive, watch } from "vue";
import useManager from "./useManager";
import useSales from "../Sales/useSales";
import useVisit from "../Visit/useVisit";
import { useQuasar } from "quasar";

import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
export default {
  setup() {
    const { data, router, route, getVisitMap } = useManager();
    const sales = useSales();
    const visit = useVisit();

    const $q = useQuasar();

    let goDetail = (kota) => {
      let filter = JSON.parse(JSON.stringify(options));
      filter.kota = kota;
      filter.selSales = sales.data.sales.find((val) => {
        return val.value == filter.selSales;
      });
      console.log(JSON.stringify(filter));
    };

    onMounted(async () => {
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        moment.locale("id");
        await getVisitMap();
        await sales.getOptSales();
        await visit.getOptYear();
        await visit.getType();
        visit.data.visitType.unshift("Semua Tipe");

        // if (route.params.filter) {
        //   let filter = JSON.parse(route.params.filter);
        //   options.selSales = filter.selSales;
        //   options.selType = filter.selType;
        //   options.date = filter.date;
        // } else {
        let firstDay = moment()
          .startOf("month")
          .format("YYYY-MM-DD");
        let lastDay = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
        options.date.from = firstDay;
        options.date.to = lastDay;
        // }

        filteringVisit();
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });

    //filtering property
    let options = reactive({
      selSales: "semua",

      date: {
        from: null,
        to: null,
      },

      selType: "Semua Tipe",
    });

    let dateModel = computed(() => {
      if (options.date.from && options.date.to) {
        return `${moment(options.date.from).format("DD MMM YYYY")} - ${moment(
          options.date.to
        ).format("DD MMM YYYY")}`;
      } else if (typeof options.date == "string") {
        return `${moment(options.date).format("DD MMM YYYY")}`;
      } else {
        return "-";
      }
    });

    //router replace area
    // watch([options], (newValues, prevValues) => {
    // let temp = newValues[0];
    // let routeFilter = JSON.stringify(temp);
    // router.replace({
    //   path: `/manager/statistic/${routeFilter}`,
    // });
    // });

    //filtering area
    let filteredVisit = ref([]);
    let filteringVisit = () => {
      let tempvisit = JSON.parse(JSON.stringify(data.visit));

      if (options.selSales != "semua") {
        tempvisit = tempvisit.filter((val) => {
          return val.id_user == options.selSales;
        });
      }

      if (options.selType != "Semua Tipe") {
        tempvisit = tempvisit.filter((val) => {
          return val.type == options.selType;
        });
      }

      if (options.date.from && options.date.to) {
        tempvisit = tempvisit.filter((val) => {
          return moment(val.date).isBetween(
            options.date.from,
            options.date.to,
            undefined,
            "[]"
          );
        });
      } else if (typeof options.date == "string") {
        tempvisit = tempvisit.filter((val) => {
          return moment(val.date).isSame(options.date);
        });
      }

      let cities = [...new Set(tempvisit.map((x) => x.city))];
      let ret = [];

      for (let item of cities) {
        ret.push({
          city: item,
          total: tempvisit.filter((x) => {
            return x.city.toLowerCase() == item.toLowerCase();
          }).length,
        });
      }

      filteredVisit.value = ret;
    };

    let downloadExcell = async () => {
      let tempvisit = JSON.parse(JSON.stringify(data.visit));

      let month = "";
      let title = "";
      if (options.date.from && options.date.to) {
        tempvisit = tempvisit.filter((val) => {
          return moment(val.date).isBetween(
            options.date.from,
            options.date.to,
            undefined,
            "[]"
          );
        });

        month = `${moment(options.date.from).format("D-M-YY")} - ${moment(
          options.date.to
        ).format("D-M-YY")}`;
        title = `${moment(options.date.from).format("DD MMMM YYYY")} - ${moment(
          options.date.to
        ).format("DD MMMM YYYY")}`;
      } else if (typeof options.date == "string") {
        tempvisit = tempvisit.filter((val) => {
          return moment(val.date).isSame(options.date);
        });

        month = moment(options.date).format("D-M-YY");
        title = moment(options.date).format("DD MMMM YYYY");
      }

      if (tempvisit.length == 0) {
        $q.notify({
          message: "Tidak Ada Data Kunjungan Pada Tanggal Yang Anda Pilih!",
          color: "negative",
        });
        return;
      }

      const workbook = new ExcelJS.Workbook();
      let ws = workbook.addWorksheet("Visit");

      ws.mergeCells("A1", "I1");
      ws.getCell("A1").value = `Laporan Kunjungan ${title}`;

      // ws.columns = [
      //   { header: "No", width: 4 },
      //   { header: "Hari / Tanggal", width: 10 },
      //   { header: "Customer", width: 13 },
      //   { header: "New/Exist", width: 10 },
      //   { header: "Ket.", width: 15 },
      //   { header: "Kota", width: 12 },
      //   { header: "Aplikasi", width: 12 },
      //   { header: "Tipe lem", width: 10 },
      //   { header: "Catatan", width: 10 },
      // ];

      ws.getRow(2).values = [
        "No",
        "Hari",
        "Customer",
        "New/Exist",
        "Ket.",
        "Kota",
        "Aplikasi",
        "Tipe Lem",
        "Notes",
      ];

      ws.columns = [
        { key: "c", width: 4 },
        { key: "date", width: 10 },
        { key: "name", width: 13 },
        { key: "customer_type", width: 10 },
        { key: "summary", width: 15 },
        { key: "city", width: 12 },
        { key: "application", width: 12 },
        { key: "tpe_lem", width: 10 },
        { key: "notes", width: 10 },
      ];

      let count = 1;
      for (let item of tempvisit) {
        // ws.addRow([
        //   count++,
        //   moment(item.date).format("dddd, D/M/YY"),
        //   item.name,
        //   item.customer_type,
        //   item.summary,
        //   item.city,
        //   item.application,
        // ]);

        let payload = {
          c: count++,
          date: moment(item.date).format("dddd, D/M/YY"),
          name: item.name,
          customer_type: item.customer_type,
          summary: item.summary,
          city: item.city,
          application: item.application,
          tipe_lem: "",
          notes: "",
        };

        ws.addRow({
          c: payload.c,
          date: payload.date,
          name: payload.name,
          customer_type: payload.customer_type,
          summary: payload.summary,
          city: payload.city,
          application: payload.application,
          tipe_lem: payload.tipe_lem,
          notes: payload.notes,
        });
      }

      // style the worksheet
      let len = tempvisit.length + 2;
      for (let i = 1; i <= 9; i++) {
        for (let j = 1; j <= len; j++) {
          let cell = `${String.fromCharCode(i + 64)}${j}`;
          if (j == 1) {
            ws.getCell(cell).alignment = {
              wrapText: true,
              vertical: "middle",
              horizontal: "center",
            };
            ws.getCell(cell).font = {
              bold: true,
              size: 18,
            };
          } else if (j == 2) {
            ws.getCell(cell).alignment = {
              wrapText: true,
              vertical: "middle",
              horizontal: "center",
            };
            ws.getCell(cell).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFCAFFFF" },
            };
            ws.getCell(cell).font = {
              bold: true,
            };
            ws.getCell(cell).border = {
              top: { style: "thick" },
              left: { style: "thick" },
              bottom: { style: "thick" },
              right: { style: "thick" },
            };
          } else {
            {
              ws.getCell(cell).alignment = { wrapText: true };
              ws.getCell(cell).border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
            }
          }
        }
      }

      const buffer = await workbook.xlsx.writeBuffer();
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const blob = new Blob([buffer], { type: fileType });
      try {
        saveAs(blob, `Laporan Kunjungan ${month}.xlsx`);
      } catch (err) {
        console.log(err);
      }
    };

    return {
      options,
      sales,
      visit,
      filteredVisit,
      dateModel,
      goDetail,
      filteringVisit,
      downloadExcell,
    };
  },
};
</script>
